import LandingPage from "./pages/LandingPage";
import "./App.css"

function App() {
  return (
    <LandingPage/>
  );
}

export default App;
